<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              :to="{ name: 'List Bookings' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("COMMON.BOOKINGS") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("COMMON.BOOKINGS") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <!-- shopping cart here -->
          <shopping-cart :key="renderKey * 200" />
        </div>
      </div>

      <booking-spot-list-table
        @onCreateBooking="openBookingCreateModal"
        @onViewBooking="openBookingModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isAddBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-booking-component
              v-if="selectedSpotId"
              :spotId="selectedSpotId"
              @onViewBooking="openBookingModal"
              @onCloseBookingModal="closeBookingModal"
              @onOpenConfirmBookingModal="onOpenConfirmBookingModal"
              :bookingData="bookingData"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isConfirmBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isConfirmBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.CONFIRM_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <confirm-booking-component
              v-if="openOrder"
              :sales-order-id="openOrder.id"
              @onCloseBookingModal="closeBookingModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isViewBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'BOOKING_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.VIEW_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openBooking"
                :objectType="'bookings'"
                :objectId="openBooking.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openBookingEditModal(openBooking)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteBooking(openBooking)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-booking-component
              v-if="openBooking"
              :bookingId="openBooking.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  Link,
  PageHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_CONFIRM,
} from "@/constants/common";
import AddBookingComponent from "./components/AddBookingComponent.vue";
import ConfirmBookingComponent from "./components/ConfirmBookingComponent.vue";
import BookingSpotListTable from "./partials/BookingSpotListTable";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import ViewBookingComponent from "./components/ViewBookingComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import { cloneDeep } from "lodash";
import ShoppingCart from "@/components/ShoppingCart";

export default {
  layout: "DashboardLayout",

  components: {
    ShoppingCart,
    WrapperExpandButton,
    BookingSpotListTable,
    ConfirmBookingComponent,
    AddBookingComponent,
    ViewBookingComponent,
    NotificationSubscription,
    [Button.name]: Button,
    [PageHeader.name]: PageHeader,
    [Link.name]: Link,
    [BreadcrumbItem.name]: BreadcrumbItem,
    [Breadcrumb.name]: Breadcrumb,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const bookingId = this.$route.query.id;
    const spotId = this.$route.query.spotId;
    const orderId = this.$route.query.orderId;
    const action = this.$route.query.action;
    const startDate = this.$route.query.startDate;
    const endDate = this.$route.query.endDate;
    let isViewBookingModalOpened = false;
    let isEditBookingModalOpened = false;
    let isAddBookingModalOpened = false;
    let isConfirmBookingModalOpened = false;
    let openBooking = null;
    let openOrder = null;
    let bookingData = {};

    if (startDate) {
      bookingData.start_at = startDate;
    }

    if (endDate) {
      bookingData.end_at = endDate;
    }

    if (action) {
      if (action === QUERY_ACTIONS_ADD) {
        isAddBookingModalOpened = true;
      } else if (action === QUERY_ACTIONS_CONFIRM && orderId) {
        isConfirmBookingModalOpened = true;

        openOrder = { id: orderId };
      } else {
        switch (action) {
          case QUERY_ACTIONS_VIEW:
            isViewBookingModalOpened = true;
            break;

          case QUERY_ACTIONS_EDIT:
            isEditBookingModalOpened = true;
            break;

          case QUERY_ACTIONS_CONFIRM:
            break;
        }

        openBooking = { id: bookingId };
      }
    }
    return {
      isViewBookingModalOpened: isViewBookingModalOpened,
      isEditBookingModalOpened: isEditBookingModalOpened,
      isAddBookingModalOpened: isAddBookingModalOpened,
      isConfirmBookingModalOpened,
      openBooking,
      openOrder,
      selectedSpotId: spotId,
      renderKey: 1,
      bookingData,
    };
  },

  methods: {
    openBookingCreateModal(
      spotId,
      startDate = null,
      endDate = null,
      reRender = false
    ) {
      this.selectedSpotId = spotId;
      this.closeBookingModal();
      this.isAddBookingModalOpened = true;

      let query = { spotId: spotId, action: QUERY_ACTIONS_ADD };

      if (startDate) {
        query = { ...query, startDate };

        this.bookingData.start_at = startDate;
      }

      if (endDate) {
        query = { ...query, endDate };

        this.bookingData.end_at = endDate;
      }

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        { spot: spotId },
        null,
        this.$router.resolve({
          name: "New Booking",
          query,
        }).href
      );
    },
    openBookingModal(booking, reRender = false) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.isViewBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    onOpenConfirmBookingModal(order, reRender = false) {
      this.closeBookingModal();
      this.openOrder = order;
      this.isConfirmBookingModalOpened = true;

      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { orderId: this.openOrder.id, action: QUERY_ACTIONS_CONFIRM },
        }).href
      );
    },

    closeBookingModal() {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = false;
      this.isEditBookingModalOpened = false;
      this.isConfirmBookingModalOpened = false;
      this.openBooking = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: {},
        }).href
      );
    },
  },
};
</script>
